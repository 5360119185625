import { ControlCommunicationService } from '@app/form-builder/services/control-communication.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, AuthenticationService, RequestsService, ReviewsService, EvaluationsService, UsersService, InspectionsService } from '@app/core/services'
import { DomSanitizer } from '@angular/platform-browser'
import { first } from 'rxjs/operators';
import { Packer } from "docx";
import { Router } from '@angular/router';
import { User } from '@app/core/models';
import { Subscription } from 'rxjs';


//Documents version 1
import { DocumentCreatorMTO_FSOL_01_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_01' //ID: 0
import { DocumentCreatorMTO_FSOL_02_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_02' //ID: 1
import { DocumentCreatorMTO_FSOL_04_1_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_04_1' //ID: 2, ID: 52 for inspection
import { DocumentCreatorMTO_FSOL_04_2_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_04_2' //ID: 16, ID: 57 for inspection
import { DocumentCreatorMTO_FSOL_05_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_05' //ID: 3
import { DocumentCreatorMTO_FSOL_06_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_06' //ID: 4
import { DocumentCreatorMTO_FSOL_07_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_07' //ID: 30 (review how form)
import { DocumentCreatorMTO_FSOL_08_1_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_1' //ID: 12
import { DocumentCreatorMTO_FSOL_08_2_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_2' //ID: 22
import { DocumentCreatorMTO_FSOL_08_3_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_3' //ID: 18
import { DocumentCreatorMTO_FSOL_08_4_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_4' //ID: 24
import { DocumentCreatorMTO_FSOL_08_5_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_5' //ID: 25
import { DocumentCreatorMTO_FSOL_08_7_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_08_7' //ID: 23
import { DocumentCreatorMTO_FSOL_09_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_09' //ID: 14
import { DocumentCreatorMTO_FSOL_10_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_10' //ID: 9
import { DocumentCreatorMTO_FSOL_11_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_11' //ID: 10
import { DocumentCreatorMTO_FSOL_14_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_14' //ID: 11
import { DocumentCreatorMTO_FSOL_23_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_23' //ID: 32 (review how form)
import { DocumentCreatorMTO_FSOL_24_v1 } from '@app/features/generator/V1/docx.MTO_FSOL_24' //ID: 17
import { DocumentCreatorMTO_FRYD_03_v1 } from '@app/features/generator/V1/docx.MTO_FRYD_03' //ID: without ID, the information get it of endpoint in the view "Orden de inspección"
import { DocumentCreatorMTO_FINS_01_v1 } from '@app/features/generator/V1/docx.MTO_FINS_01' //ID: 40
import { DocumentCreatorMTO_FINS_02_v1 } from '@app/features/generator/V1/docx.MTO_FINS_02' //ID: 50
import { DocumentCreatorMTO_FRYD_05_v1 } from '@app/features/generator/V1/docx.MTO_FRYD_05' //ID: 68
import { DocumentCreatorMTO_FSOL_08_1_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_1_inspection" //ID: 51
import { DocumentCreatorMTO_FSOL_08_2_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_2_inspection" //ID: 54
import { DocumentCreatorMTO_FSOL_08_3_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_3_inspection" //ID: 56
import { DocumentCreatorMTO_FSOL_08_4_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_4_inspection" //ID: 59
import { DocumentCreatorMTO_FSOL_08_5_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_5_inspection" //ID: 61
import { DocumentCreatorMTO_FSOL_08_7_INSPECTION_v1 } from "@app/features/generator/V1/docx.MTO_FSOL_08_7_inspection" //ID: 63
import { DocumentCreatorMTO_FINS_03_1_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_1' //ID: 53
import { DocumentCreatorMTO_FINS_03_2_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_2' //ID: 55
import { DocumentCreatorMTO_FINS_03_3_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_3' //ID: 58
import { DocumentCreatorMTO_FINS_03_4_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_4' //ID: 60
import { DocumentCreatorMTO_FINS_03_5_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_5' //ID: 62
import { DocumentCreatorMTO_FINS_03_7_v1 } from '@app/features/generator/V1/docx.MTO_FINS_03_7' //ID: 64
import { DocumentCreatorMTO_FINS_04_v1 } from '@app/features/generator/V1/docx.MTO_FINS_04'; //ID: 65, but when is generating the finding ID: 68
import { DocumentCreatorMTO_FINS_06_v1 } from '@app/features/generator/V1/docx.MTO_FINS_06'; //ID: 66, but when is loaded the laboratory results ID: 91

//Documents version 2
import { DocumentCreatorMTO_FSOL_01_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_01' //ID: 200
import { DocumentCreatorMTO_FSOL_01_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_01_1' //ID: without ID, it's generated if document with ID 200 exist
import { DocumentCreatorMTO_FSOL_02_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_02_1' //ID: 201
import { DocumentCreatorMTO_FSOL_02_2_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_02_2' //ID: 279
import { DocumentCreatorMTO_FSOL_04_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_04_1' //ID: 202, ID: 252 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_04_2_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_04_2' //ID: 270, ID: 285 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_04_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_04_3' //ID: 216, ID: 257 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_05_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_05_1' //ID: 203
import { DocumentCreatorMTO_FSOL_05_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_05_3' //ID: 271
import { DocumentCreatorMTO_FSOL_06_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_06' //ID: 204
import { DocumentCreatorMTO_FSOL_07_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_07' //ID: 230 (review how form)
import { DocumentCreatorMTO_FSOL_08_1_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_1' //ID: 212, ID: 251 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_2_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_2' //ID: 272, ID: 286 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_3' //ID: 273, ID: 287 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_4_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_4' //ID: 224, ID: 259 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_5_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_1_5' //ID: 225, ID: 261 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_1_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_2_1' //ID: 222, ID: 254 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_2_3' //ID: 223, ID: 263 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_4_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_2_4' //ID: 275, ID: 289 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_3_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_3' //ID: 218, ID: 256 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_4_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_08_4' //ID: 276, ID: 290 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_09_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_09' //ID: 214
import { DocumentCreatorMTO_FSOL_10_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_10' //ID: 209
import { DocumentCreatorMTO_FSOL_11_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_11' //ID: 210
import { DocumentCreatorMTO_FSOL_12_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_12' //ID: 220
import { DocumentCreatorMTO_FSOL_13_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_13' //ID: 221
import { DocumentCreatorMTO_FSOL_14_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_14' //ID: 211
import { DocumentCreatorMTO_FSOL_21_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_21' //ID: 278
import { DocumentCreatorMTO_FSOL_23_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_23' //ID: 232 (review how form)
import { DocumentCreatorMTO_FSOL_24_v2 } from '@app/features/generator/V2/docx.MTO_FSOL_24' //ID: 217
import { DocumentCreatorMTO_FRYD_03_v2 } from '@app/features/generator/V2/docx.MTO_FRYD_03' //ID: without ID, the information get it of endpoint in the view "Orden de inspección"
import { DocumentCreatorMTO_FINS_01_v2 } from '@app/features/generator/V2/docx.MTO_FINS_01' //ID: 240
import { DocumentCreatorMTO_FINS_02_v2 } from '@app/features/generator/V2/docx.MTO_FINS_02' //ID: 250
import { DocumentCreatorMTO_FRYD_05_v2 } from '@app/features/generator/V2/docx.MTO_FRYD_05' //ID: 268
import { DocumentCreatorMTO_FRYD_01_v2 } from '@app/features/generator/V2/docx.MTO_FRYD_01'    //ID: 293
import { DocumentCreatorMTO_FINS_03_1_1_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_1' //ID: 253
import { DocumentCreatorMTO_FINS_03_1_2_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_2' //ID: 280
import { DocumentCreatorMTO_FINS_03_1_3_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_3' //ID: 281
import { DocumentCreatorMTO_FINS_03_1_4_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_4' //ID: 260
import { DocumentCreatorMTO_FINS_03_1_5_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_1_5' //ID: 262
import { DocumentCreatorMTO_FINS_03_2_1_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_2_1' //ID: 255
import { DocumentCreatorMTO_FINS_03_2_3_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_2_3' //ID: 264
import { DocumentCreatorMTO_FINS_03_2_4_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_2_4' //ID: 283
import { DocumentCreatorMTO_FINS_03_3_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_3' //ID: 258
import { DocumentCreatorMTO_FINS_03_4_v2 } from '@app/features/generator/V2/docx.MTO_FINS_03_4' //ID: 284
import { DocumentCreatorMTO_FINS_04_v2 } from '@app/features/generator/V2/docx.MTO_FINS_04'; //ID: 265, but when is generating the finding ID: 268
import { DocumentCreatorMTO_FINS_06_v2 } from '@app/features/generator/V2/docx.MTO_FINS_06'; //ID: 266, but when is loaded the laboratory results ID: 291

//Documents version 3
import { DocumentCreatorMTO_FSOL_01_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_01' //ID: 300
import { DocumentCreatorMTO_FSOL_01_1_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_01_1' //ID: without ID, it's generated if document with ID 300 exist
import { DocumentCreatorMTO_FSOL_02_1_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_02_1' //ID: 301
import { DocumentCreatorMTO_FSOL_02_2_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_02_2' //ID: 379
import { DocumentCreatorMTO_FSOL_02_3_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_02_3' //ID: 394
import { DocumentCreatorMTO_FSOL_04_1_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_04_1' //ID: 302, ID: 352 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_04_2_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_04_2' //ID: 370, ID: 385 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_04_3_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_04_3' //ID: 316, ID: 357 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_04_4_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_04_4' //ID: 395, ID: 399 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_05_1_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_05_1' //ID: 303
import { DocumentCreatorMTO_FSOL_05_3_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_05_3' //ID: 371
import { DocumentCreatorMTO_FSOL_06_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_06' //ID: 304
import { DocumentCreatorMTO_FSOL_07_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_07' //ID: 330 (review how form)
import { DocumentCreatorMTO_FSOL_08_1_1_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_1_1' //ID: 312, ID: 351 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_2_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_1_2' //ID: 372, ID: 386 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_3_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_1_3' //ID: 373, ID: 387 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_4_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_1_4' //ID: 324, ID: 359 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_1_5_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_1_5' //ID: 325, ID: 361 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_1_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_2_1' //ID: 322, ID: 354 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_3_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_2_3' //ID: 323, ID: 363 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_2_4_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_2_4' //ID: 375, ID: 389 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_3_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_3' //ID: 318, ID: 356 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_08_4_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_08_4' //ID: 376, ID: 390 for inspection/evaluation
import { DocumentCreatorMTO_FSOL_09_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_09' //ID: 314
import { DocumentCreatorMTO_FSOL_10_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_10' //ID: 309
import { DocumentCreatorMTO_FSOL_11_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_11' //ID: 310
import { DocumentCreatorMTO_FSOL_12_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_12' //ID: 320
import { DocumentCreatorMTO_FSOL_13_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_13' //ID: 321
import { DocumentCreatorMTO_FSOL_14_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_14' //ID: 311
import { DocumentCreatorMTO_FSOL_14_UE_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_14_UE' //ID: 327
import { DocumentCreatorMTO_FSOL_21_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_21' //ID: 378
import { DocumentCreatorMTO_FSOL_23_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_23' //ID: 332 (review how form)
import { DocumentCreatorMTO_FSOL_24_v3 } from '@app/features/generator/V3/docx.MTO_FSOL_24' //ID: 317
import { DocumentCreatorMTO_FRYD_03_v3 } from '@app/features/generator/V3/docx.MTO_FRYD_03' //ID: without ID, the information get it of endpoint in the view "Orden de inspección"
import { DocumentCreatorMTO_FINS_01_v3 } from '@app/features/generator/V3/docx.MTO_FINS_01' //ID: 340
import { DocumentCreatorMTO_FINS_02_v3 } from '@app/features/generator/V3/docx.MTO_FINS_02' //ID: 350
import { DocumentCreatorMTO_FRYD_05_v3 } from '@app/features/generator/V3/docx.MTO_FRYD_05' //ID: 368
import { DocumentCreatorMTO_FRYD_01_v3 } from '@app/features/generator/V3/docx.MTO_FRYD_01'    //ID: 393
import { DocumentCreatorMTO_FINS_03_1_1_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_1_1' //ID: 353
import { DocumentCreatorMTO_FINS_03_1_2_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_1_2' //ID: 380
import { DocumentCreatorMTO_FINS_03_1_3_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_1_3' //ID: 381
import { DocumentCreatorMTO_FINS_03_1_4_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_1_4' //ID: 360
import { DocumentCreatorMTO_FINS_03_1_5_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_1_5' //ID: 362
import { DocumentCreatorMTO_FINS_03_1_6_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_1_6' //ID: 399
import { DocumentCreatorMTO_FINS_03_2_1_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_2_1' //ID: 355
import { DocumentCreatorMTO_FINS_03_2_3_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_2_3' //ID: 364
import { DocumentCreatorMTO_FINS_03_2_4_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_2_4' //ID: 383
import { DocumentCreatorMTO_FINS_03_3_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_3' //ID: 358
import { DocumentCreatorMTO_FINS_03_4_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_4' //ID: 384
import { DocumentCreatorMTO_FINS_03_12_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_12' //ID: 397
import { DocumentCreatorMTO_FINS_03_13_v3 } from '@app/features/generator/V3/docx.MTO_FINS_03_13' //ID: 398
import { DocumentCreatorMTO_FINS_04_v3 } from '@app/features/generator/V3/docx.MTO_FINS_04'; //ID: 365, but when is generating the finding ID: 368
import { DocumentCreatorMTO_FINS_06_v3 } from '@app/features/generator/V3/docx.MTO_FINS_06'; //ID: 366, but when is loaded the laboratory results ID: 391
import { DocumentCreatorMTO_FINS_08_v3 } from '@app/features/generator/V3/docx.MTO_FINS_08'; //ID: 328
import { DocumentCreatorMTO_FRYD_14_v3 } from '@app/features/generator/V3/docx.MTO_FRYD_14'; // ID: 338, is generated after evaluation


@Component({
    selector: 'modal-preview',
    templateUrl: 'modal-preview.component.html',
    styleUrls: ['../../../app.component.scss']
})
export class ModalPreview implements OnInit, OnDestroy {
    form: FormGroup;
    formOrder: FormGroup;
    @Input() public name = ''
    @Input() public formId = null
    @Input() public reviewId
    @Input() public isEvaluation = false
    @Input() public isInspection = false
    @Input() public isAnInspectionDoc = false
    @Input() public isChecklist = false
    @Input() public showReview = true
    @Input() public showEval = false
    @Input() public toSave = false
    @Input() public isOrderInspection = false
    @Input() public inspectionId = 0
    @Input() public isAcceptedOrder = false
    @Input() public signatureUrl = ''
    @Input() public signatureUrl2 = ''
    @Input() public isNC = false
    @Input() public indexRFYD = 0
    @Input() public dictator = ''
    @Input() public isFinding = false
    @Input() public urlFinding = ''
    @Input() public isResultReview = false
    @Input() public isResultReviewVersion = '1'
    @Input() public dataResult = null
    @Input() public isContract = false
    @Input() public urlContract = ''
    @Input() public isApplication = false

    loading = false;
    docloading = true
    submitted = false;
    res = false
    reviewers = []
    forbiddenId = false;
    submission = null
    requirements = null
    response = null
    showForm = false
    showDoc = false
    bdoc
    url
    saving = false

    attachments = []

    title = ''
    data
    
    private subscriptions: Subscription

    constructor(
        private formBuilder: FormBuilder,
        public modal: NgbActiveModal,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private reviewsService: ReviewsService,
        private evaluationService: EvaluationsService,
        private sanitizer: DomSanitizer,
        private communication: ControlCommunicationService,
        private inspectionService: InspectionsService,
        private users: UsersService,
        private router: Router,
        private requestsService: RequestsService
    ) {
        this.subscriptions = this.communication.changeEmmited$.subscribe(async msg => {
            //console.log(msg.identifier, this.saving)
            if (msg.identifier === 'send-form' && !this.saving) {
                const formGroup = msg.form as FormGroup
                const f = formGroup.value
                let data = f
                const originalData = JSON.parse(JSON.stringify(data))

                for (var i in this.requirements) {
                    const requirement = this.requirements[i]
                    if (Object.keys(requirement.data).length > 0) {
                        if (requirement.isDynamic) {
                            let nval = []
                            for (var j in requirement.data) {
                                const r = requirement.data[j]
                                let s = {}
                                for (var k in Object.keys(r)) {
                                    const key = Object.keys(r)[k]
                                    s[key] = r[key]
                                }
                                nval.push(s)
                            }
                            data[requirement.requiredInto] = nval
                        } else {
                            data[requirement.requiredInto] = requirement.data
                        }
                    }
                }

                // Load correctly MTO-FSOL-13 in review
                if([30, 230, 330].includes(this.formId)) {
                    for(let i in data.lista_sustancias) {
                        const substance = data.lista_sustancias[i]

                        data.lista_sustancias[i] = {
                            ...substance,
                            restricciones: originalData.lista_sustancias[i]?.restricciones ?? substance?.restricciones,
                            verificacion_lpo: originalData.lista_sustancias[i]?.verificacion_lpo ?? substance?.verificacion_lpo,
                            verificacion_cor: originalData.lista_sustancias[i]?.verificacion_cor ?? substance?.verificacion_cor,
                            verificacion_ue: originalData.lista_sustancias[i]?.verificacion_ue ?? substance?.verificacion_ue,
                            verificacion_no_permitido: originalData.lista_sustancias[i]?.verificacion_no_permitido ?? substance?.verificacion_no_permitido,
                        }
                    }
                }

                // Load correctly MTO-FSOL-13 in review
                if([32, 232, 332].includes(this.formId)) {
                    for(let i in data.lista_proveedores) {
                        let obj = data.lista_proveedores[i]

                        if(this.formId === 32) {
                            obj["certificacion_lpo_fecha_vigencia"] = obj["certificacion_lpo_fecha_vigencia"] !== ''
                                ? "LPO " + obj["certificacion_lpo_fecha_vigencia"] + (obj["certificacion_lpo_en_vigencia"]
                                    ? " Vigencia"
                                    : obj["certificacion_lpo_en_aniversario"]
                                        ? " Aniversario"
                                        : "")
                                : ''

                            obj["certificacion_opr_fecha_vigencia"] = obj["certificacion_opr_fecha_vigencia"] !== ''
                                ? "OPR " + obj["certificacion_opr_fecha_vigencia"] + (obj["certificacion_opr_en_vigencia"]
                                    ? " Vigencia"
                                    : obj["certificacion_opr_en_aniversario"]
                                        ? " Aniversario"
                                        : "")
                                : ''

                            obj["certificacion_nop_fecha_vigencia"] = obj["certificacion_nop_fecha_vigencia"] !== ''
                                ? "NOP " + obj["certificacion_nop_fecha_vigencia"] + (obj["certificacion_nop_en_vigencia"]
                                    ? " Vigencia"
                                    : obj["certificacion_nop_en_aniversario"]
                                        ? " Aniversario"
                                        : "")
                                : ''

                            obj["certificacion_otro_fecha_vigencia"] = obj["certificacion_otro_fecha_vigencia"] !== ''
                                ? obj["certificacion_otro_text"] + " " + obj["certificacion_otro_fecha_vigencia"] + (obj["certificacion_otro_en_vigencia"]
                                    ? " Vigencia"
                                    : obj["certificacion_otro_en_aniversario"]
                                        ? " Aniversario"
                                        : "")
                                : ''
                        }

                        if(this.formId === 232) {
                            obj["certificacion_lpo_fecha_vigencia"] = obj["certificacion_lpo_fecha_vigencia"] !== ''
                                ? "LPO " + obj["certificacion_lpo_fecha_vigencia"] + (obj["certificacion_lpo_en_vigencia"]
                                    ? " Vigencia"
                                    : obj["certificacion_lpo_en_aniversario"]
                                        ? " Aniversario"
                                        : "")
                                : ''

                            obj["certificacion_cor_fecha_vigencia"] = obj["certificacion_cor_fecha_vigencia"] !== ''
                                ? "COR " + obj["certificacion_cor_fecha_vigencia"] + (obj["certificacion_cor_en_vigencia"]
                                    ? " Vigencia"
                                    : obj["certificacion_cor_en_aniversario"]
                                        ? " Aniversario"
                                        : "")
                                : ''

                            obj["certificacion_nop_fecha_vigencia"] = obj["certificacion_nop_fecha_vigencia"] !== ''
                                ? "NOP " + obj["certificacion_nop_fecha_vigencia"] + (obj["certificacion_nop_en_vigencia"]
                                    ? " Vigencia"
                                    : obj["certificacion_nop_en_aniversario"]
                                        ? " Aniversario"
                                        : "")
                                : ''

                            obj["certificacion_otro_fecha_vigencia"] = obj["certificacion_otro_fecha_vigencia"] !== ''
                                ? obj["certificacion_otro_text"] + " " + obj["certificacion_otro_fecha_vigencia"] + (obj["certificacion_otro_en_vigencia"]
                                    ? " Vigencia"
                                    : obj["certificacion_otro_en_aniversario"]
                                        ? " Aniversario"
                                        : "")
                                : ''
                        }

                        if(this.formId === 332) {
                            // LPO
                            if(obj['certificacion_lpo_en_vigencia'] && obj['certificacion_lpo_en_aniversario'] && obj['certificacion_lpo_fecha_vigencia'] !== '') {
                                obj['certificacion_lpo_fecha_vigencia'] = `LPO ${obj["certificacion_lpo_fecha_vigencia"]} Vigencia y Última emisión`
                            } else if((obj['certificacion_lpo_en_vigencia'] || obj['certificacion_lpo_en_aniversario']) && obj['certificacion_lpo_fecha_vigencia'] !== '') {
                                if(obj['certificacion_lpo_en_vigencia']) obj['certificacion_lpo_fecha_vigencia'] = `LPO ${obj["certificacion_lpo_fecha_vigencia"]} Vigencia`
                                else if(obj['certificacion_lpo_en_aniversario']) obj['certificacion_lpo_fecha_vigencia'] = `LPO ${obj["certificacion_lpo_fecha_vigencia"]} Última emisión`
                            } else {
                                obj['certificacion_lpo_fecha_vigencia'] = ''
                            }

                            // COR/USCOEA
                            if(obj['certificacion_cor_en_vigencia'] && obj['certificacion_cor_en_aniversario'] && obj['certificacion_cor_fecha_vigencia'] !== '') {
                                obj['certificacion_cor_fecha_vigencia'] = `COR ${obj["certificacion_cor_fecha_vigencia"]} Vigencia y Última emisión`
                            } else if((obj['certificacion_cor_en_vigencia'] || obj['certificacion_cor_en_aniversario']) && obj['certificacion_cor_fecha_vigencia'] !== '') {
                                if(obj['certificacion_cor_en_vigencia']) obj['certificacion_cor_fecha_vigencia'] = `COR ${obj["certificacion_cor_fecha_vigencia"]} Vigencia`
                                else if(obj['certificacion_cor_en_aniversario']) obj['certificacion_cor_fecha_vigencia'] = `COR ${obj["certificacion_cor_fecha_vigencia"]} Última emisión`
                            } else {
                                obj['certificacion_cor_fecha_vigencia'] = ''
                            }


                            // NOP
                            if(obj['certificacion_nop_en_vigencia'] && obj['certificacion_nop_en_aniversario'] && obj['certificacion_nop_fecha_vigencia'] !== '') {
                                obj['certificacion_nop_fecha_vigencia'] = `NOP ${obj["certificacion_nop_fecha_vigencia"]} Vigencia y Última emisión`
                            } else if((obj['certificacion_nop_en_vigencia'] || obj['certificacion_nop_en_aniversario']) && obj['certificacion_nop_fecha_vigencia'] !== '') {
                                if(obj['certificacion_nop_en_vigencia']) obj['certificacion_nop_fecha_vigencia'] = `NOP ${obj["certificacion_nop_fecha_vigencia"]} Vigencia`
                                else if(obj['certificacion_nop_en_aniversario']) obj['certificacion_nop_fecha_vigencia'] = `NOP ${obj["certificacion_nop_fecha_vigencia"]} Última emisión`
                            } else {
                                obj['certificacion_nop_fecha_vigencia'] = ''
                            }


                            // UE
                            if(obj['certificacion_ue_en_vigencia'] && obj['certificacion_ue_en_aniversario'] && obj['certificacion_ue_fecha_vigencia'] !== '') {
                                obj['certificacion_ue_fecha_vigencia'] = `UE ${obj["certificacion_ue_fecha_vigencia"]} Vigencia y Última emisión`
                            } else if((obj['certificacion_ue_en_vigencia'] || obj['certificacion_ue_en_aniversario']) && obj['certificacion_ue_fecha_vigencia'] !== '') {
                                if(obj['certificacion_ue_en_vigencia']) obj['certificacion_ue_fecha_vigencia'] = `UE ${obj["certificacion_ue_fecha_vigencia"]} Vigencia`
                                else if(obj['certificacion_ue_en_aniversario']) obj['certificacion_ue_fecha_vigencia'] = `UE ${obj["certificacion_ue_fecha_vigencia"]} Última emisión`
                            } else {
                                obj['certificacion_ue_fecha_vigencia'] = ''
                            }


                            // Otro
                            if(obj['certificacion_otro_en_vigencia'] && obj['certificacion_otro_en_aniversario'] && obj['certificacion_otro_fecha_vigencia'] !== '') {
                                obj['certificacion_otro_fecha_vigencia'] = `${obj['certificacion_otro_text']} ${obj["certificacion_otro_fecha_vigencia"]} Vigencia y Última emisión`
                            } else if((obj['certificacion_otro_en_vigencia'] || obj['certificacion_otro_en_aniversario']) && obj['certificacion_otro_fecha_vigencia'] !== '') {
                                if(obj['certificacion_otro_en_vigencia']) obj['certificacion_otro_fecha_vigencia'] = `${obj['certificacion_otro_text']} ${obj["certificacion_otro_fecha_vigencia"]} Vigencia`
                                else if(obj['certificacion_otro_en_aniversario']) obj['certificacion_otro_fecha_vigencia'] = `${obj['certificacion_otro_text']} ${obj["certificacion_otro_fecha_vigencia"]} Última emisión`
                            } else {
                                obj['certificacion_otro_fecha_vigencia'] = ''
                            }
                        }

                        data.lista_proveedores[i] = {
                            ...obj,
                        }
                    }
                }

                await setTimeout(() => {
                    this.communication.emitEvent({
                        identifier: 'set-values',
                        form: data
                    })
                }, 100);
            } else if (msg.identifier === 'send-form') {
                const formGroup = msg.form as FormGroup
                const f = formGroup.value

                if (this.isEvaluation) {
                    this.reviewsService.saveForm(this.reviewId, this.formId, f)
                        .pipe(first())
                        .subscribe(data => {
                            //console.log(data)
                            if (this.toSave) this.saving = false
                        }, error => {
                            console.log(error)
                        })
                } else {
                    const formsFSOL08s = [
                        12, 24, 25, 22, 23, 18, 
                        212, 272, 273, 224, 225, 222, 274, 223, 275, 218, 276,
                        312, 372, 373, 324, 325, 322, 374, 323, 375, 318, 376
                    ]
                    const formsFSOL07s = [30, 230, 330]

                    //Validation forms 08's from review
                    if (formsFSOL08s.includes(this.formId)) {
                        //console.log("es 08-submit")
                        let isValid = true
                        if (
                            f.plan_organico_conformidad_no_conformidades_graves ||
                            f.plan_organico_conformidad_no_conformidades_menores_con_hallazgos ||
                            f.plan_organico_conformidad_no_conformidades_menores ||
                            f.plan_organico_conformidad_conformidad ||
                            (f.plan_organico_conformidad_otro && f.plan_organico_conformidad_otro_text !== '')
                        ) {
                            isValid = true
                        } else {
                            isValid = false
                        }

                        if (isValid) {
                            //Save form and sign review
                            this.reviewsService.saveForm(this.reviewId, this.formId, f)
                                .pipe(first())
                                .subscribe(data => {
                                    //console.log(data)
                                    if (this.toSave) this.saving = false

                                    this.reviewsService.signReview(this.reviewId, this.formId)
                                        .pipe(first())
                                        .subscribe(
                                            response => {
                                                this.saving = false
                                                this.modal.close(true)
                                                this.modal.dismiss()
                                            },
                                            error => {
                                                this.alertService.error(error);
                                            })

                                }, error => {
                                    this.alertService.error(error);
                                })

                        } else {
                            this.alertService.warn('Debe indicarse la conformidad de la evaluación del documento');
                        }
                    } else if(formsFSOL07s.includes(this.formId)) {
                        //console.log("es 07-submit")
                        let isValid = true
                        
                        let listOfSubstances = f.lista_sustancias
                        for(var i in listOfSubstances) {
                            const substance = listOfSubstances[i]

                            //console.log(substance.verificacion_cor, substance.verificacion_lpo, substance.verificacion_no_permitido, substance.verificacion_opr)
                            if(substance.verificacion_cor || substance.verificacion_lpo || substance.verificacion_no_permitido || substance.verificacion_opr || substance.verificacion_ue) {
                                //console.log("tiene al menos una seleccion")
                                continue
                            } else {
                                //console.log("no tiene ninguna seleccion: ", i)

                                isValid = false
                                break
                            }
                        }

                        if(isValid) {
                            //Save form and sign review
                            this.reviewsService.saveForm(this.reviewId, this.formId, f)
                                .pipe(first())
                                .subscribe(data => {
                                    //console.log(data)
                                    if (this.toSave) this.saving = false

                                    this.reviewsService.signReview(this.reviewId, this.formId)
                                        .pipe(first())
                                        .subscribe(
                                            response => {
                                                this.saving = false
                                                this.modal.close(true)
                                                this.modal.dismiss()
                                            },
                                            error => {
                                                this.alertService.error(error);
                                            })
                                }, error => {
                                    this.alertService.error(error);
                                })
                        } else {
                            this.alertService.warn('Debe indicarse la verificación para cada una de las sustancias');
                        }
                    } else {
                        //console.log("no es 08-submit ni 07-submit")
                        //Save form and sign review
                        this.reviewsService.saveForm(this.reviewId, this.formId, f)
                            .pipe(first())
                            .subscribe(data => {
                                //console.log(data)
                                if (this.toSave) this.saving = false
                                this.reviewsService.signReview(this.reviewId, this.formId)
                                    .pipe(first())
                                    .subscribe(
                                        response => {
                                            this.saving = false
                                            this.modal.close(true)
                                            this.modal.dismiss()
                                        },
                                        error => {
                                            this.alertService.error(error);
                                        })
                            }, error => {
                                this.alertService.error(error);
                            })
                    }
                }
            }
        })
    }

    getFilename(url: string) {
        if (url.constructor.name !== 'String') {
            return ''
        }
        const parts = url.split("/")
        const name = parts[parts.length - 1]
        return name
    }

    loadAttachments(submission: any) {
        // puede ser un array
        if (Array.isArray(submission)) {
            for (var i in submission) {
                const elem = submission[i]
                this.loadAttachments(elem)
            }
            // puede ser un string
        } else if (typeof submission === 'string') {
            if (submission.includes('http')) {
                this.attachments.push(submission)
            }
            // o puede ser un objeto
        } else if (typeof submission === 'object' && submission) {
            const keys = Object.keys(submission)
            for (var i in keys) {
                this.loadAttachments(submission[keys[i]])
            }
        }
    }

    private async generateDocument(showDoc: boolean, docLoading: boolean, showForm: boolean, formMapp, methodMapp): Promise<any> {
        this.showForm = showForm;
        this.showDoc = showDoc;
        this.docloading = docLoading;
        //console.log("Generating document with form id:", this.formId)
        const form = formMapp[this.formId];
        const s = new form.creatorClass();
        let doc
        //check if the form id has a generator
        if (this.formId in methodMapp) {
            const methodName = methodMapp[this.formId].trim();
            if (s && typeof s[methodName] === 'function') {
                //console.log('calling function: ', methodName)
                doc = await s[methodName](this.response, form?.requirement_1, form?.requirement_2, form?.requirement_3);
            }
        }
        if (this.response.special !== null) {
            let lastForm = {};
            for (let i in this.response.special) {
                const obj = this.response.special[i];
                lastForm[obj.name] = obj.value;
            }
            setTimeout(() => {
                this.communication.emitEvent({
                    identifier: 'set-values',
                    form: lastForm
                });
            }, 100);
        }
        
        return doc ?? null;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    async ngOnInit() {
        this.saving = false

        if (this.isContract) {
            this.title =  this.name === '' ? "Contrato" : this.name
            this.showForm = false
            this.showDoc = true
            this.showEval = false
            setTimeout(() => {
                this.url = this.urlContract
                this.docloading = false
            }, 3000);
        } else if (this.isResultReview) {
            this.showDoc = true
            this.showForm = false
            this.showEval = false
            this.showReview = false
            this.toSave = false

            let format
            if(this.isResultReviewVersion === '1') format = new DocumentCreatorMTO_FRYD_03_v1()
            if(this.isResultReviewVersion === '2') format = new DocumentCreatorMTO_FRYD_03_v2()
            if(this.isResultReviewVersion === '3') format = new DocumentCreatorMTO_FRYD_03_v3()

            const document = await format.create_MTO_FRYD_03(this.dataResult, this.signatureUrl)
            this.buildDocument(document, '')
        } else if (this.isFinding) {
            this.title = "MTO-FRYD-05"
            this.showForm = false
            this.showDoc = true
            this.showEval = false
            setTimeout(() => {
                this.url = this.urlFinding
                this.docloading = false
            }, 3000);
        } else if (this.isNC) {
            this.evaluationService.getForm(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(
                    async response => {
                        this.title = response.form.title
                        this.submission = response.submission
                        this.requirements = response.requirements
                        this.response = response

                        // Define a mapping of formId values to DocumentCreator classes
                        const creatorMapping = {
                            68: DocumentCreatorMTO_FRYD_05_v1,
                            268: DocumentCreatorMTO_FRYD_05_v2,
                            368: DocumentCreatorMTO_FRYD_05_v3
                        };

                        // Check if the current formId exists in the mapping
                        let document
                        if (this.formId in creatorMapping) {
                            const DocumentCreatorClass = creatorMapping[this.formId];
                            const format = new DocumentCreatorClass();
                            document = await format.create_MTO_FRYD_05(response, this.indexRFYD);

                            if (this.response.special !== null) {
                                let lastForm = {};
                                for (let i in this.response.special) {
                                    const obj = this.response.special[i];
                                    lastForm[obj.name] = obj.value;
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'set-values',
                                        form: lastForm
                                    });
                                }, 100);
                            }
                        } else {
                            this.showDoc = false;
                            this.showForm = true;
                            this.docloading = false;

                            if (this.submission !== null) {
                                let lastForm = {};
                                for (let i in this.submission) {
                                    const obj = this.submission[i];
                                    lastForm[obj.name] = obj.value;
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'set-values',
                                        form: lastForm
                                    });
                                }, 100);
                            }
                        }

                        // Emit submit event in any case
                        setTimeout(() => {
                            this.communication.emitEvent({
                                identifier: 'submit'
                            });
                        }, 100);

                        if(document) this.buildDocument(document, "")
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else if (this.formId === -1) {
            this.title = "MTO-FSOL-01-1"
            this.showForm = false
            this.showDoc = true
            const format = new DocumentCreatorMTO_FSOL_01_1_v2()
            const document = await format.create_MTO_FSOL_01_1()
            this.buildDocument(document, '')
        } else if([226, 326].includes(this.formId) && !this.showReview) {
            this.inspectionService.getForm(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(
                    async response => {
                        this.title = response.data.form.title
                        this.submission = response.data.submission
                        this.requirements = response.data.requirements
                        this.response = response.data
                        this.loadAttachments(this.submission)

                        this.showForm = false
                        this.showDoc = true

                        setTimeout(() => {
                            this.url = this.attachments[0]
                            this.docloading = false
                        }, 3000);
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else if (this.isEvaluation) {
            this.evaluationService.getForm(this.reviewId, this.formId, this.isAnInspectionDoc, this.inspectionId)
                .pipe(first())
                .subscribe(
                    async response => {
                        this.title = this.name
                        this.submission = response.submission
                        this.requirements = response.requirements
                        this.response = response

                        //Get files specifics of form 'Reporte de Hallazgos', else get files normally
                        if([68, 268, 368].includes(this.formId)) {
                            for(let i in this.submission[0].value) {
                                const value = this.submission[0].value[i]
                                this.loadAttachments(value.informe_documentos_url)
                                this.loadAttachments(value.files)
                            }
                        } else {
                            this.loadAttachments(this.submission)
                        }
                        

                        const formMappings = {
                            // Version 1
                            0: { creatorClass: DocumentCreatorMTO_FSOL_01_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            1: { creatorClass: DocumentCreatorMTO_FSOL_02_v1, requirement_1: this.signatureUrl },
                            3: { creatorClass: DocumentCreatorMTO_FSOL_05_v1 },
                            4: { creatorClass: DocumentCreatorMTO_FSOL_06_v1, requirement_1: this.signatureUrl },
                            9: { creatorClass: DocumentCreatorMTO_FSOL_10_v1, requirement_1: this.signatureUrl },
                            10: { creatorClass: DocumentCreatorMTO_FSOL_11_v1, requirement_1: this.signatureUrl },
                            11: { creatorClass: DocumentCreatorMTO_FSOL_14_v1, requirement_1: this.signatureUrl },
                            14: { creatorClass: DocumentCreatorMTO_FSOL_09_v1, requirement_1: this.signatureUrl },
                            2: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v1, requirement_1: this.signatureUrl },
                            52: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v1, requirement_1: this.signatureUrl },
                            16: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v1, requirement_1: this.signatureUrl },
                            57: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v1, requirement_1: this.signatureUrl },
                            17: { creatorClass: DocumentCreatorMTO_FSOL_24_v1, requirement_1: this.signatureUrl },
                            30: { creatorClass: DocumentCreatorMTO_FSOL_07_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            32: { creatorClass: DocumentCreatorMTO_FSOL_23_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            51: { creatorClass: DocumentCreatorMTO_FSOL_08_1_INSPECTION_v1, requirement_1: this.signatureUrl },
                            54: { creatorClass: DocumentCreatorMTO_FSOL_08_2_INSPECTION_v1, requirement_1: this.signatureUrl },
                            56: { creatorClass: DocumentCreatorMTO_FSOL_08_3_INSPECTION_v1, requirement_1: this.signatureUrl },
                            59: { creatorClass: DocumentCreatorMTO_FSOL_08_4_INSPECTION_v1 },
                            61: { creatorClass: DocumentCreatorMTO_FSOL_08_5_INSPECTION_v1 },
                            63: { creatorClass: DocumentCreatorMTO_FSOL_08_7_INSPECTION_v1 },
                            40: { creatorClass: DocumentCreatorMTO_FINS_01_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            50: { creatorClass: DocumentCreatorMTO_FINS_02_v1, requirement_1: this.signatureUrl2 },
                            53: { creatorClass: DocumentCreatorMTO_FINS_03_1_v1 },
                            55: { creatorClass: DocumentCreatorMTO_FINS_03_2_v1 },
                            58: { creatorClass: DocumentCreatorMTO_FINS_03_3_v1 },
                            60: { creatorClass: DocumentCreatorMTO_FINS_03_4_v1 },
                            62: { creatorClass: DocumentCreatorMTO_FINS_03_5_v1 },
                            64: { creatorClass: DocumentCreatorMTO_FINS_03_7_v1 },
                            65: { creatorClass: DocumentCreatorMTO_FINS_04_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            66: { creatorClass: DocumentCreatorMTO_FINS_06_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            68: { creatorClass: DocumentCreatorMTO_FINS_04_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            91: { creatorClass: DocumentCreatorMTO_FINS_06_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            // Version 2
                            200: { creatorClass: DocumentCreatorMTO_FSOL_01_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            201: { creatorClass: DocumentCreatorMTO_FSOL_02_1_v2, requirement_1: this.signatureUrl },
                            279: { creatorClass: DocumentCreatorMTO_FSOL_02_2_v2, requirement_1: this.signatureUrl },
                            202: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v2, requirement_1: this.signatureUrl },
                            252: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v2, requirement_1: this.signatureUrl },
                            285: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v2, requirement_1: this.signatureUrl },
                            270: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v2, requirement_1: this.signatureUrl },
                            216: { creatorClass: DocumentCreatorMTO_FSOL_04_3_v2, requirement_1: this.signatureUrl },
                            257: { creatorClass: DocumentCreatorMTO_FSOL_04_3_v2, requirement_1: this.signatureUrl },
                            203: { creatorClass: DocumentCreatorMTO_FSOL_05_1_v2 },
                            271: { creatorClass: DocumentCreatorMTO_FSOL_05_3_v2 },
                            204: { creatorClass: DocumentCreatorMTO_FSOL_06_v2, requirement_1: this.signatureUrl },
                            209: { creatorClass: DocumentCreatorMTO_FSOL_10_v2, requirement_1: this.signatureUrl },
                            210: { creatorClass: DocumentCreatorMTO_FSOL_11_v2, requirement_1: this.signatureUrl },
                            211: { creatorClass: DocumentCreatorMTO_FSOL_14_v2, requirement_1: this.signatureUrl },
                            214: { creatorClass: DocumentCreatorMTO_FSOL_09_v2, requirement_1: this.signatureUrl },
                            217: { creatorClass: DocumentCreatorMTO_FSOL_24_v2, requirement_1: this.signatureUrl },
                            220: { creatorClass: DocumentCreatorMTO_FSOL_12_v2, requirement_1: this.signatureUrl },
                            221: { creatorClass: DocumentCreatorMTO_FSOL_13_v2, requirement_1: this.signatureUrl },
                            230: { creatorClass: DocumentCreatorMTO_FSOL_07_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            278: { creatorClass: DocumentCreatorMTO_FSOL_21_v2, requirement_1: this.signatureUrl },
                            232: { creatorClass: DocumentCreatorMTO_FSOL_23_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            251: { creatorClass: DocumentCreatorMTO_FSOL_08_1_1_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            286: { creatorClass: DocumentCreatorMTO_FSOL_08_1_2_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            287: { creatorClass: DocumentCreatorMTO_FSOL_08_1_3_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            259: { creatorClass: DocumentCreatorMTO_FSOL_08_1_4_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            261: { creatorClass: DocumentCreatorMTO_FSOL_08_1_5_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            254: { creatorClass: DocumentCreatorMTO_FSOL_08_2_1_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            263: { creatorClass: DocumentCreatorMTO_FSOL_08_2_3_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            289: { creatorClass: DocumentCreatorMTO_FSOL_08_2_4_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            256: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            290: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v2, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            240: { creatorClass: DocumentCreatorMTO_FINS_01_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            250: { creatorClass: DocumentCreatorMTO_FINS_02_v2, requirement_1: this.signatureUrl2 },
                            253: { creatorClass: DocumentCreatorMTO_FINS_03_1_1_v2, requirement_1: this.requirements?.[1]?.data },
                            280: { creatorClass: DocumentCreatorMTO_FINS_03_1_2_v2, requirement_1: this.requirements?.[1]?.data },
                            281: { creatorClass: DocumentCreatorMTO_FINS_03_1_3_v2, requirement_1: this.requirements?.[1]?.data },
                            260: { creatorClass: DocumentCreatorMTO_FINS_03_1_4_v2, requirement_1: this.requirements?.[1]?.data },
                            262: { creatorClass: DocumentCreatorMTO_FINS_03_1_5_v2, requirement_1: this.requirements?.[1]?.data },
                            255: { creatorClass: DocumentCreatorMTO_FINS_03_2_1_v2, requirement_1: this.requirements?.[3]?.data },
                            264: { creatorClass: DocumentCreatorMTO_FINS_03_2_3_v2, requirement_1: this.requirements?.[1]?.data },
                            283: { creatorClass: DocumentCreatorMTO_FINS_03_2_4_v2, requirement_1: this.requirements?.[2]?.data },
                            258: { creatorClass: DocumentCreatorMTO_FINS_03_3_v2, requirement_1: this.requirements?.[2]?.data },
                            284: { creatorClass: DocumentCreatorMTO_FINS_03_4_v2, requirement_1: this.requirements?.[2]?.data },
                            265: { creatorClass: DocumentCreatorMTO_FINS_04_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            268: { creatorClass: DocumentCreatorMTO_FINS_04_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            266: { creatorClass: DocumentCreatorMTO_FINS_06_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            291: { creatorClass: DocumentCreatorMTO_FINS_06_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            293: { creatorClass: DocumentCreatorMTO_FRYD_01_v2 },
                            // Version 3
                            300: { creatorClass: DocumentCreatorMTO_FSOL_01_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            301: { creatorClass: DocumentCreatorMTO_FSOL_02_1_v3, requirement_1: this.signatureUrl },
                            379: { creatorClass: DocumentCreatorMTO_FSOL_02_2_v3, requirement_1: this.signatureUrl },
                            394: { creatorClass: DocumentCreatorMTO_FSOL_02_3_v3, requirement_1: this.signatureUrl },
                            302: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v3, requirement_1: this.signatureUrl },
                            352: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v3, requirement_1: this.signatureUrl },
                            385: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v3, requirement_1: this.signatureUrl },
                            370: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v3, requirement_1: this.signatureUrl },
                            316: { creatorClass: DocumentCreatorMTO_FSOL_04_3_v3, requirement_1: this.signatureUrl },
                            357: { creatorClass: DocumentCreatorMTO_FSOL_04_3_v3, requirement_1: this.signatureUrl },
                            395: { creatorClass: DocumentCreatorMTO_FSOL_04_4_v3, requirement_1: this.signatureUrl },
                            399: { creatorClass: DocumentCreatorMTO_FSOL_04_4_v3, requirement_1: this.signatureUrl },
                            303: { creatorClass: DocumentCreatorMTO_FSOL_05_1_v3 },
                            371: { creatorClass: DocumentCreatorMTO_FSOL_05_3_v3 },
                            304: { creatorClass: DocumentCreatorMTO_FSOL_06_v3, requirement_1: this.signatureUrl },
                            309: { creatorClass: DocumentCreatorMTO_FSOL_10_v3, requirement_1: this.signatureUrl },
                            310: { creatorClass: DocumentCreatorMTO_FSOL_11_v3, requirement_1: this.signatureUrl },
                            311: { creatorClass: DocumentCreatorMTO_FSOL_14_v3, requirement_1: this.signatureUrl },
                            327: { creatorClass: DocumentCreatorMTO_FSOL_14_UE_v3, requirement_1: this.signatureUrl },
                            314: { creatorClass: DocumentCreatorMTO_FSOL_09_v3, requirement_1: this.signatureUrl },
                            317: { creatorClass: DocumentCreatorMTO_FSOL_24_v3, requirement_1: this.signatureUrl },
                            320: { creatorClass: DocumentCreatorMTO_FSOL_12_v3, requirement_1: this.signatureUrl },
                            321: { creatorClass: DocumentCreatorMTO_FSOL_13_v3, requirement_1: this.signatureUrl },
                            330: { creatorClass: DocumentCreatorMTO_FSOL_07_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            378: { creatorClass: DocumentCreatorMTO_FSOL_21_v3, requirement_1: this.signatureUrl },
                            332: { creatorClass: DocumentCreatorMTO_FSOL_23_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            312: { creatorClass: DocumentCreatorMTO_FSOL_08_1_1_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            351: { creatorClass: DocumentCreatorMTO_FSOL_08_1_1_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            372: { creatorClass: DocumentCreatorMTO_FSOL_08_1_2_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            386: { creatorClass: DocumentCreatorMTO_FSOL_08_1_2_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            373: { creatorClass: DocumentCreatorMTO_FSOL_08_1_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            387: { creatorClass: DocumentCreatorMTO_FSOL_08_1_3_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            324: { creatorClass: DocumentCreatorMTO_FSOL_08_1_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            359: { creatorClass: DocumentCreatorMTO_FSOL_08_1_4_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            325: { creatorClass: DocumentCreatorMTO_FSOL_08_1_5_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            361: { creatorClass: DocumentCreatorMTO_FSOL_08_1_5_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            322: { creatorClass: DocumentCreatorMTO_FSOL_08_2_1_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            354: { creatorClass: DocumentCreatorMTO_FSOL_08_2_1_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            323: { creatorClass: DocumentCreatorMTO_FSOL_08_2_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            363: { creatorClass: DocumentCreatorMTO_FSOL_08_2_3_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            375: { creatorClass: DocumentCreatorMTO_FSOL_08_2_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            389: { creatorClass: DocumentCreatorMTO_FSOL_08_2_4_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            318: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            356: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            376: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            390: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v3, requirement_1: false, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            340: { creatorClass: DocumentCreatorMTO_FINS_01_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            350: { creatorClass: DocumentCreatorMTO_FINS_02_v3, requirement_1: this.signatureUrl2 },
                            353: { creatorClass: DocumentCreatorMTO_FINS_03_1_1_v3, requirement_1: this.requirements?.[1]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            380: { creatorClass: DocumentCreatorMTO_FINS_03_1_2_v3, requirement_1: this.requirements?.[1]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            381: { creatorClass: DocumentCreatorMTO_FINS_03_1_3_v3, requirement_1: this.requirements?.[1]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            360: { creatorClass: DocumentCreatorMTO_FINS_03_1_4_v3, requirement_1: this.requirements?.[1]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            362: { creatorClass: DocumentCreatorMTO_FINS_03_1_5_v3, requirement_1: this.requirements?.[1]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            396: { creatorClass: DocumentCreatorMTO_FINS_03_1_6_v3, requirement_1: this.requirements?.[1]?.data, requirement_2: this.signatureUrl },
                            355: { creatorClass: DocumentCreatorMTO_FINS_03_2_1_v3, requirement_1: this.requirements?.[3]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            364: { creatorClass: DocumentCreatorMTO_FINS_03_2_3_v3, requirement_1: this.requirements?.[1]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            383: { creatorClass: DocumentCreatorMTO_FINS_03_2_4_v3, requirement_1: this.requirements?.[2]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            358: { creatorClass: DocumentCreatorMTO_FINS_03_3_v3, requirement_1: this.requirements?.[2]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            384: { creatorClass: DocumentCreatorMTO_FINS_03_4_v3, requirement_1: this.requirements?.[2]?.data, requirement_2: this.signatureUrl, requirement_3: this.signatureUrl2 },
                            397: { creatorClass: DocumentCreatorMTO_FINS_03_12_v3, requirement_1: this.requirements?.[0]?.data },
                            398: { creatorClass: DocumentCreatorMTO_FINS_03_13_v3, requirement_1: this.requirements?.[0]?.data },
                            365: { creatorClass: DocumentCreatorMTO_FINS_04_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            368: { creatorClass: DocumentCreatorMTO_FINS_04_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            366: { creatorClass: DocumentCreatorMTO_FINS_06_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            391: { creatorClass: DocumentCreatorMTO_FINS_06_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            328: { creatorClass: DocumentCreatorMTO_FINS_08_v3 },
                            393: { creatorClass: DocumentCreatorMTO_FRYD_01_v3 },
                            338: { creatorClass: DocumentCreatorMTO_FRYD_14_v3 },
                        }

                        const methodMappings = {
                            // Version 1
                            0: 'create_MTO_FSOL_01',
                            1: 'create_MTO_FSOL_02',
                            3: 'create_MTO_FSOL_05',
                            4: 'create_MTO_FSOL_06',
                            9: 'create_MTO_FSOL_10',
                            10: 'create_MTO_FSOL_11',
                            11: 'create_MTO_FSOL_14',
                            14: 'create_MTO_FSOL_09',
                            2: 'create_MTO_FSOL_04_1',
                            52: 'create_MTO_FSOL_04_1',
                            16: 'create_MTO_FSOL_04_2',
                            57: 'create_MTO_FSOL_04_2',
                            17: 'create_MTO_FSOL_24',
                            30: 'create_MTO_FSOL_07',
                            32: 'create_MTO_FSOL_23',
                            51: 'create_MTO_FSOL_08_1',
                            54: 'create_MTO_FSOL_08_2',
                            56: 'create_MTO_FSOL_08_3',
                            59: 'create_MTO_FSOL_08_4',
                            61: 'create_MTO_FSOL_08_5',
                            63: 'create_MTO_FSOL_08_7',
                            40: 'create_MTO_FINS_01',
                            50: 'create_MTO_FINS_02',
                            53: 'create_MTO_FINS_03_1',
                            55: 'create_MTO_FINS_03_2',
                            58: 'create_MTO_FINS_03_3',
                            60: 'create_MTO_FINS_03_4',
                            62: 'create_MTO_FINS_03_5',
                            64: 'create_MTO_FINS_03_7',
                            65: 'create_MTO_FINS_04',
                            66: 'create_MTO_FINS_06',
                            68: 'create_MTO_FINS_04',
                            91: 'create_MTO_FINS_06',
                            // Version 2
                            200: 'create_MTO_FSOL_01',
                            201: 'create_MTO_FSOL_02_1',
                            279: 'create_MTO_FSOL_02_2',
                            202: 'create_MTO_FSOL_04_1',
                            252: 'create_MTO_FSOL_04_1',
                            285: 'create_MTO_FSOL_04_2',
                            270: 'create_MTO_FSOL_04_2',
                            216: 'create_MTO_FSOL_04_3',
                            257: 'create_MTO_FSOL_04_3',
                            203: 'create_MTO_FSOL_05_1',
                            271: 'create_MTO_FSOL_05_3',
                            204: 'create_MTO_FSOL_06',
                            209: 'create_MTO_FSOL_10',
                            210: 'create_MTO_FSOL_11',
                            211: 'create_MTO_FSOL_14',
                            214: 'create_MTO_FSOL_09',
                            217: 'create_MTO_FSOL_24',
                            220: 'create_MTO_FSOL_12',
                            221: 'create_MTO_FSOL_13',
                            230: 'create_MTO_FSOL_07',
                            278: 'create_MTO_FSOL_21',
                            232: 'create_MTO_FSOL_23',
                            251: 'create_MTO_FSOL_08_1_1',
                            286: 'create_MTO_FSOL_08_1_2',
                            287: 'create_MTO_FSOL_08_1_3',
                            259: 'create_MTO_FSOL_08_1_4',
                            261: 'create_MTO_FSOL_08_1_5',
                            254: 'create_MTO_FSOL_08_2_1',
                            263: 'create_MTO_FSOL_08_2_3',
                            289: 'create_MTO_FSOL_08_2_4',
                            256: 'create_MTO_FSOL_08_3',
                            290: 'create_MTO_FSOL_08_4',
                            240: 'create_MTO_FINS_01',
                            250: 'create_MTO_FINS_02',
                            253: 'create_MTO_FINS_03_1_1',
                            280: 'create_MTO_FINS_03_1_2',
                            281: 'create_MTO_FINS_03_1_3',
                            260: 'create_MTO_FINS_03_1_4',
                            262: 'create_MTO_FINS_03_1_5',
                            255: 'create_MTO_FINS_03_2_1',
                            264: 'create_MTO_FINS_03_2_3',
                            283: 'create_MTO_FINS_03_2_4',
                            258: 'create_MTO_FINS_03_3',
                            284: 'create_MTO_FINS_03_4',
                            265: 'create_MTO_FINS_04',
                            268: 'create_MTO_FINS_04',
                            266: 'create_MTO_FINS_06',
                            291: 'create_MTO_FINS_06',
                            293: 'create_MTO_FRYD_01',
                            // Version 3
                            300: 'create_MTO_FSOL_01',
                            301: 'create_MTO_FSOL_02_1',
                            379: 'create_MTO_FSOL_02_2',
                            394: 'create_MTO_FSOL_02_3',
                            302: 'create_MTO_FSOL_04_1',
                            352: 'create_MTO_FSOL_04_1',
                            385: 'create_MTO_FSOL_04_2',
                            370: 'create_MTO_FSOL_04_2',
                            316: 'create_MTO_FSOL_04_3',
                            357: 'create_MTO_FSOL_04_3',
                            395: 'create_MTO_FSOL_04_4',
                            399: 'create_MTO_FSOL_04_4',
                            303: 'create_MTO_FSOL_05_1',
                            371: 'create_MTO_FSOL_05_3',
                            304: 'create_MTO_FSOL_06',
                            309: 'create_MTO_FSOL_10',
                            310: 'create_MTO_FSOL_11',
                            311: 'create_MTO_FSOL_14',
                            327: 'create_MTO_FSOL_14',
                            314: 'create_MTO_FSOL_09',
                            317: 'create_MTO_FSOL_24',
                            320: 'create_MTO_FSOL_12',
                            321: 'create_MTO_FSOL_13',
                            330: 'create_MTO_FSOL_07',
                            378: 'create_MTO_FSOL_21',
                            332: 'create_MTO_FSOL_23',
                            312: 'create_MTO_FSOL_08_1_1',
                            351: 'create_MTO_FSOL_08_1_1',
                            372: 'create_MTO_FSOL_08_1_2',
                            386: 'create_MTO_FSOL_08_1_2',
                            373: 'create_MTO_FSOL_08_1_3',
                            387: 'create_MTO_FSOL_08_1_3',
                            324: 'create_MTO_FSOL_08_1_4',
                            359: 'create_MTO_FSOL_08_1_4',
                            325: 'create_MTO_FSOL_08_1_5',
                            361: 'create_MTO_FSOL_08_1_5',
                            322: 'create_MTO_FSOL_08_2_1',
                            354: 'create_MTO_FSOL_08_2_1',
                            323: 'create_MTO_FSOL_08_2_3',
                            363: 'create_MTO_FSOL_08_2_3',
                            375: 'create_MTO_FSOL_08_2_4',
                            389: 'create_MTO_FSOL_08_2_4',
                            318: 'create_MTO_FSOL_08_3',
                            356: 'create_MTO_FSOL_08_3',
                            376: 'create_MTO_FSOL_08_4',
                            390: 'create_MTO_FSOL_08_4',
                            340: 'create_MTO_FINS_01',
                            350: 'create_MTO_FINS_02',
                            353: 'create_MTO_FINS_03_1_1',
                            380: 'create_MTO_FINS_03_1_2',
                            381: 'create_MTO_FINS_03_1_3',
                            360: 'create_MTO_FINS_03_1_4',
                            362: 'create_MTO_FINS_03_1_5',
                            396: 'create_MTO_FINS_03_1_6',
                            355: 'create_MTO_FINS_03_2_1',
                            364: 'create_MTO_FINS_03_2_3',
                            383: 'create_MTO_FINS_03_2_4',
                            358: 'create_MTO_FINS_03_3',
                            384: 'create_MTO_FINS_03_4',
                            397: 'create_MTO_FINS_03_12',
                            398: 'create_MTO_FINS_03_13',
                            365: 'create_MTO_FINS_04',
                            368: 'create_MTO_FINS_04',
                            366: 'create_MTO_FINS_06',
                            391: 'create_MTO_FINS_06',
                            328: 'create_MTO_FINS_08',
                            393: 'create_MTO_FRYD_01',
                            338: 'create_MTO_FRYD_14',
                        }

                        // Load document
                        if (this.formId in formMappings) {
                            const document = await this.generateDocument(true, true, false, formMappings, methodMappings);
                            this.buildDocument(document, "")
                        } else {
                            this.showDoc = false
                            this.showForm = true
                            this.docloading = false

                            if (this.submission !== null) {
                                let lastForm = {}
                                for (var i in this.submission) {
                                    const obj = this.submission[i]
                                    lastForm[obj.name] = obj.value
                                }

                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'set-values',
                                        form: lastForm
                                    })
                                }, 100)
                            }

                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'submit'
                                })
                            }, 100)
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else if (this.isInspection) {
            this.inspectionService.getForm(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(
                    async response => {
                        this.title = response.data.form.title
                        this.submission = response.data.submission
                        this.loadAttachments(this.submission)
                        this.requirements = response.data.requirements
                        this.response = response.data

                        const formMappings = {
                            // Version 1
                            50: { creatorClass: DocumentCreatorMTO_FINS_02_v1, requirement_1: this.signatureUrl },
                            40: { creatorClass: DocumentCreatorMTO_FINS_01_v1, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            // Version 2
                            250: { creatorClass: DocumentCreatorMTO_FINS_02_v2, requirement_1: this.signatureUrl },
                            240: { creatorClass: DocumentCreatorMTO_FINS_01_v2, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                            // Version 3
                            350: { creatorClass: DocumentCreatorMTO_FINS_02_v3, requirement_1: this.signatureUrl },
                            340: { creatorClass: DocumentCreatorMTO_FINS_01_v3, requirement_1: this.signatureUrl, requirement_2: this.signatureUrl2 },
                        }

                        const methodMappings = {
                            // Version 1
                            40: 'create_MTO_FINS_01',
                            50: 'create_MTO_FINS_02',
                            // Version 2
                            240: 'create_MTO_FINS_01',
                            250: 'create_MTO_FINS_02',
                            // Version 3
                            340: 'create_MTO_FINS_01',
                            350: 'create_MTO_FINS_02',
                        }

                        if (this.formId in formMappings) {
                            const document = await this.generateDocument(true, true, false, formMappings, methodMappings);
                            this.buildDocument(document, "")
                        } else {
                            this.showDoc = false
                            this.showForm = true
                            this.docloading = false

                            if (this.submission !== null) {
                                let lastForm = {}
                                for (var i in this.submission) {
                                    const obj = this.submission[i]
                                    lastForm[obj.name] = obj.value
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'set-values',
                                        form: lastForm
                                    })
                                }, 100)
                            }

                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'submit'
                                })
                            }, 100)
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else if (this.isApplication) {
            this.requestsService.getFormPreview(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(
                    async response => {
                        this.title = response.form.title
                        this.submission = response.submission
                        this.loadAttachments(this.submission)
                        this.requirements = response.requirements
                        this.response = response

                        const formMappings = {
                            // Version 1
                            0: { creatorClass: DocumentCreatorMTO_FSOL_01_v1, requirement_1: this.signatureUrl },
                            1: { creatorClass: DocumentCreatorMTO_FSOL_02_v1, requirement_1: this.signatureUrl },
                            2: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v1, requirement_1: this.signatureUrl },
                            16: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v1, requirement_1: this.signatureUrl },
                            3: { creatorClass: DocumentCreatorMTO_FSOL_05_v1, requirement_1: this.signatureUrl },
                            4: { creatorClass: DocumentCreatorMTO_FSOL_06_v1, requirement_1: this.signatureUrl },
                            12: { creatorClass: DocumentCreatorMTO_FSOL_08_1_v1, requirement_1: this.signatureUrl },
                            24: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v1, requirement_1: this.signatureUrl },
                            25: { creatorClass: DocumentCreatorMTO_FSOL_08_5_v1, requirement_1: this.signatureUrl },
                            22: { creatorClass: DocumentCreatorMTO_FSOL_08_2_v1, requirement_1: this.signatureUrl },
                            23: { creatorClass: DocumentCreatorMTO_FSOL_08_7_v1, requirement_1: this.signatureUrl },
                            18: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v1, requirement_1: this.signatureUrl },
                            14: { creatorClass: DocumentCreatorMTO_FSOL_09_v1, requirement_1: this.signatureUrl },
                            9: { creatorClass: DocumentCreatorMTO_FSOL_10_v1, requirement_1: this.signatureUrl },
                            10: { creatorClass: DocumentCreatorMTO_FSOL_11_v1, requirement_1: this.signatureUrl },
                            11: { creatorClass: DocumentCreatorMTO_FSOL_14_v1, requirement_1: this.signatureUrl },
                            17: { creatorClass: DocumentCreatorMTO_FSOL_24_v1, requirement_1: this.signatureUrl },
                            13: { creatorClass: DocumentCreatorMTO_FSOL_07_v1, requirement_1: this.signatureUrl },
                            15: { creatorClass: DocumentCreatorMTO_FSOL_23_v1, requirement_1: this.signatureUrl },
                            // Version 2
                            200: { creatorClass: DocumentCreatorMTO_FSOL_01_v2, requirement_1: this.signatureUrl },
                            201: { creatorClass: DocumentCreatorMTO_FSOL_02_1_v2, requirement_1: this.signatureUrl },
                            279: { creatorClass: DocumentCreatorMTO_FSOL_02_2_v2, requirement_1: this.signatureUrl },
                            202: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v2, requirement_1: this.signatureUrl },
                            270: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v2, requirement_1: this.signatureUrl },
                            216: { creatorClass: DocumentCreatorMTO_FSOL_04_3_v2, requirement_1: this.signatureUrl },
                            203: { creatorClass: DocumentCreatorMTO_FSOL_05_1_v2, requirement_1: this.signatureUrl },
                            271: { creatorClass: DocumentCreatorMTO_FSOL_05_3_v2, requirement_1: this.signatureUrl },
                            204: { creatorClass: DocumentCreatorMTO_FSOL_06_v2, requirement_1: this.signatureUrl },
                            212: { creatorClass: DocumentCreatorMTO_FSOL_08_1_1_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            272: { creatorClass: DocumentCreatorMTO_FSOL_08_1_2_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            273: { creatorClass: DocumentCreatorMTO_FSOL_08_1_3_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            224: { creatorClass: DocumentCreatorMTO_FSOL_08_1_4_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            225: { creatorClass: DocumentCreatorMTO_FSOL_08_1_5_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            222: { creatorClass: DocumentCreatorMTO_FSOL_08_2_1_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            223: { creatorClass: DocumentCreatorMTO_FSOL_08_2_3_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            275: { creatorClass: DocumentCreatorMTO_FSOL_08_2_4_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            218: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            276: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            214: { creatorClass: DocumentCreatorMTO_FSOL_09_v2, requirement_1: this.signatureUrl },
                            209: { creatorClass: DocumentCreatorMTO_FSOL_10_v2, requirement_1: this.signatureUrl },
                            210: { creatorClass: DocumentCreatorMTO_FSOL_11_v2, requirement_1: this.signatureUrl },
                            220: { creatorClass: DocumentCreatorMTO_FSOL_12_v2, requirement_1: this.signatureUrl },
                            221: { creatorClass: DocumentCreatorMTO_FSOL_13_v2, requirement_1: this.signatureUrl },
                            211: { creatorClass: DocumentCreatorMTO_FSOL_14_v2, requirement_1: this.signatureUrl },
                            278: { creatorClass: DocumentCreatorMTO_FSOL_21_v2, requirement_1: this.signatureUrl },
                            217: { creatorClass: DocumentCreatorMTO_FSOL_24_v2, requirement_1: this.signatureUrl },
                            213: { creatorClass: DocumentCreatorMTO_FSOL_07_v2, requirement_1: this.signatureUrl },
                            215: { creatorClass: DocumentCreatorMTO_FSOL_23_v2, requirement_1: this.signatureUrl },
                            // Version 3
                            300: { creatorClass: DocumentCreatorMTO_FSOL_01_v3, requirement_1: this.signatureUrl },
                            301: { creatorClass: DocumentCreatorMTO_FSOL_02_1_v3, requirement_1: this.signatureUrl },
                            379: { creatorClass: DocumentCreatorMTO_FSOL_02_2_v3, requirement_1: this.signatureUrl },
                            394: { creatorClass: DocumentCreatorMTO_FSOL_02_3_v3, requirement_1: this.signatureUrl },
                            302: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v3, requirement_1: this.signatureUrl },
                            370: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v3, requirement_1: this.signatureUrl },
                            316: { creatorClass: DocumentCreatorMTO_FSOL_04_3_v3, requirement_1: this.signatureUrl },
                            395: { creatorClass: DocumentCreatorMTO_FSOL_04_4_v3, requirement_1: this.signatureUrl },
                            303: { creatorClass: DocumentCreatorMTO_FSOL_05_1_v3, requirement_1: this.signatureUrl },
                            371: { creatorClass: DocumentCreatorMTO_FSOL_05_3_v3, requirement_1: this.signatureUrl },
                            304: { creatorClass: DocumentCreatorMTO_FSOL_06_v3, requirement_1: this.signatureUrl },
                            312: { creatorClass: DocumentCreatorMTO_FSOL_08_1_1_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            372: { creatorClass: DocumentCreatorMTO_FSOL_08_1_2_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            373: { creatorClass: DocumentCreatorMTO_FSOL_08_1_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            324: { creatorClass: DocumentCreatorMTO_FSOL_08_1_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            325: { creatorClass: DocumentCreatorMTO_FSOL_08_1_5_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            322: { creatorClass: DocumentCreatorMTO_FSOL_08_2_1_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            323: { creatorClass: DocumentCreatorMTO_FSOL_08_2_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            375: { creatorClass: DocumentCreatorMTO_FSOL_08_2_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            318: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            376: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            314: { creatorClass: DocumentCreatorMTO_FSOL_09_v3, requirement_1: this.signatureUrl },
                            309: { creatorClass: DocumentCreatorMTO_FSOL_10_v3, requirement_1: this.signatureUrl },
                            310: { creatorClass: DocumentCreatorMTO_FSOL_11_v3, requirement_1: this.signatureUrl },
                            320: { creatorClass: DocumentCreatorMTO_FSOL_12_v3, requirement_1: this.signatureUrl },
                            321: { creatorClass: DocumentCreatorMTO_FSOL_13_v3, requirement_1: this.signatureUrl },
                            311: { creatorClass: DocumentCreatorMTO_FSOL_14_v3, requirement_1: this.signatureUrl },
                            327: { creatorClass: DocumentCreatorMTO_FSOL_14_UE_v3, requirement_1: this.signatureUrl },
                            378: { creatorClass: DocumentCreatorMTO_FSOL_21_v3, requirement_1: this.signatureUrl },
                            317: { creatorClass: DocumentCreatorMTO_FSOL_24_v3, requirement_1: this.signatureUrl },
                            313: { creatorClass: DocumentCreatorMTO_FSOL_07_v3, requirement_1: this.signatureUrl },
                            315: { creatorClass: DocumentCreatorMTO_FSOL_23_v3, requirement_1: this.signatureUrl },
                        }

                        const methodMappings = {
                            // Version 1
                            0: 'create_MTO_FSOL_01',
                            1: 'create_MTO_FSOL_02',
                            2: 'create_MTO_FSOL_04_1',
                            16: 'create_MTO_FSOL_04_2',
                            3: 'create_MTO_FSOL_05',
                            4: 'create_MTO_FSOL_06',
                            12: 'create_MTO_FSOL_08_1',
                            24: 'create_MTO_FSOL_08_4',
                            25: 'create_MTO_FSOL_08_5',
                            22: 'create_MTO_FSOL_08_2',
                            23: 'create_MTO_FSOL_08_7',
                            18: 'create_MTO_FSOL_08_3',
                            14: 'create_MTO_FSOL_09',
                            9: 'create_MTO_FSOL_10',
                            10: 'create_MTO_FSOL_11',
                            11: 'create_MTO_FSOL_14',
                            17: 'create_MTO_FSOL_24',
                            13: 'create_MTO_FSOL_07',
                            15: 'create_MTO_FSOL_23',
                            // Version 2
                            200: 'create_MTO_FSOL_01',
                            201: 'create_MTO_FSOL_02_1',
                            279: 'create_MTO_FSOL_02_2',
                            202: 'create_MTO_FSOL_04_1',
                            270: 'create_MTO_FSOL_04_2',
                            216: 'create_MTO_FSOL_04_3',
                            203: 'create_MTO_FSOL_05_1',
                            271: 'create_MTO_FSOL_05_3',
                            204: 'create_MTO_FSOL_06',
                            212: 'create_MTO_FSOL_08_1_1',
                            272: 'create_MTO_FSOL_08_1_2',
                            273: 'create_MTO_FSOL_08_1_3',
                            224: 'create_MTO_FSOL_08_1_4',
                            225: 'create_MTO_FSOL_08_1_5',
                            222: 'create_MTO_FSOL_08_2_1',
                            223: 'create_MTO_FSOL_08_2_3',
                            275: 'create_MTO_FSOL_08_2_4',
                            218: 'create_MTO_FSOL_08_3',
                            276: 'create_MTO_FSOL_08_4',
                            214: 'create_MTO_FSOL_09',
                            209: 'create_MTO_FSOL_10',
                            210: 'create_MTO_FSOL_11',
                            220: 'create_MTO_FSOL_12',
                            221: 'create_MTO_FSOL_13',
                            211: 'create_MTO_FSOL_14',
                            278: 'create_MTO_FSOL_21',
                            217: 'create_MTO_FSOL_24',
                            213: 'create_MTO_FSOL_07',
                            215: 'create_MTO_FSOL_23',
                            // Version 3
                            300: 'create_MTO_FSOL_01',
                            301: 'create_MTO_FSOL_02_1',
                            379: 'create_MTO_FSOL_02_2',
                            394: 'create_MTO_FSOL_02_3',
                            302: 'create_MTO_FSOL_04_1',
                            370: 'create_MTO_FSOL_04_2',
                            316: 'create_MTO_FSOL_04_3',
                            395: 'create_MTO_FSOL_04_4',
                            303: 'create_MTO_FSOL_05_1',
                            371: 'create_MTO_FSOL_05_3',
                            304: 'create_MTO_FSOL_06',
                            312: 'create_MTO_FSOL_08_1_1',
                            372: 'create_MTO_FSOL_08_1_2',
                            373: 'create_MTO_FSOL_08_1_3',
                            324: 'create_MTO_FSOL_08_1_4',
                            325: 'create_MTO_FSOL_08_1_5',
                            322: 'create_MTO_FSOL_08_2_1',
                            323: 'create_MTO_FSOL_08_2_3',
                            375: 'create_MTO_FSOL_08_2_4',
                            318: 'create_MTO_FSOL_08_3',
                            376: 'create_MTO_FSOL_08_4',
                            314: 'create_MTO_FSOL_09',
                            309: 'create_MTO_FSOL_10',
                            310: 'create_MTO_FSOL_11',
                            320: 'create_MTO_FSOL_12',
                            321: 'create_MTO_FSOL_13',
                            311: 'create_MTO_FSOL_14',
                            327: 'create_MTO_FSOL_14',
                            378: 'create_MTO_FSOL_21',
                            317: 'create_MTO_FSOL_24',
                            313: 'create_MTO_FSOL_07',
                            315: 'create_MTO_FSOL_23',
                        }

                        let document
                        if(this.formId in formMappings) {
                            document = await this.generateDocument(true, true, false, formMappings, methodMappings)
                        } else {
                            this.showDoc = false
                            this.showForm = true
                            if (this.submission !== null) {
                                let lastForm = {}
                                for (var i in this.submission) {
                                    const obj = this.submission[i]
                                    lastForm[obj.name] = obj.value
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'set-values',
                                        form: lastForm
                                    })
                                }, 100);
                            }
                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'submit'
                                })
                            }, 100);
                        }

                        if(document) this.buildDocument(document, '')
                    },
                    error => {
                        this.alertService.error(error);
                    })
        } else {
            this.reviewsService.getForm(this.reviewId, this.formId)
                .pipe(first())
                .subscribe(
                    async response => {
                        this.title = response.form.title
                        this.submission = response.submission
                        if(![226, 326].includes(this.formId)) this.loadAttachments(this.submission)
                        this.requirements = response.requirements
                        this.response = response

                        const formMappings = {
                            // Version 1
                            0: { creatorClass: DocumentCreatorMTO_FSOL_01_v1, requirement_1: this.signatureUrl },
                            1: { creatorClass: DocumentCreatorMTO_FSOL_02_v1, requirement_1: this.signatureUrl },
                            2: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v1, requirement_1: this.signatureUrl },
                            16: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v1, requirement_1: this.signatureUrl },
                            3: { creatorClass: DocumentCreatorMTO_FSOL_05_v1, requirement_1: this.signatureUrl },
                            4: { creatorClass: DocumentCreatorMTO_FSOL_06_v1, requirement_1: this.signatureUrl },
                            12: { creatorClass: DocumentCreatorMTO_FSOL_08_1_v1, requirement_1: this.signatureUrl },
                            24: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v1, requirement_1: this.signatureUrl },
                            25: { creatorClass: DocumentCreatorMTO_FSOL_08_5_v1, requirement_1: this.signatureUrl },
                            22: { creatorClass: DocumentCreatorMTO_FSOL_08_2_v1, requirement_1: this.signatureUrl },
                            23: { creatorClass: DocumentCreatorMTO_FSOL_08_7_v1, requirement_1: this.signatureUrl },
                            18: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v1, requirement_1: this.signatureUrl },
                            14: { creatorClass: DocumentCreatorMTO_FSOL_09_v1, requirement_1: this.signatureUrl },
                            9: { creatorClass: DocumentCreatorMTO_FSOL_10_v1, requirement_1: this.signatureUrl },
                            10: { creatorClass: DocumentCreatorMTO_FSOL_11_v1, requirement_1: this.signatureUrl },
                            11: { creatorClass: DocumentCreatorMTO_FSOL_14_v1, requirement_1: this.signatureUrl },
                            17: { creatorClass: DocumentCreatorMTO_FSOL_24_v1, requirement_1: this.signatureUrl },
                            // Version 2
                            200: { creatorClass: DocumentCreatorMTO_FSOL_01_v2, requirement_1: this.signatureUrl },
                            201: { creatorClass: DocumentCreatorMTO_FSOL_02_1_v2, requirement_1: this.signatureUrl },
                            279: { creatorClass: DocumentCreatorMTO_FSOL_02_2_v2, requirement_1: this.signatureUrl },
                            202: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v2, requirement_1: this.signatureUrl },
                            270: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v2, requirement_1: this.signatureUrl },
                            216: { creatorClass: DocumentCreatorMTO_FSOL_04_3_v2, requirement_1: this.signatureUrl },
                            203: { creatorClass: DocumentCreatorMTO_FSOL_05_1_v2, requirement_1: this.signatureUrl },
                            271: { creatorClass: DocumentCreatorMTO_FSOL_05_3_v2, requirement_1: this.signatureUrl },
                            204: { creatorClass: DocumentCreatorMTO_FSOL_06_v2, requirement_1: this.signatureUrl },
                            212: { creatorClass: DocumentCreatorMTO_FSOL_08_1_1_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            272: { creatorClass: DocumentCreatorMTO_FSOL_08_1_2_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            273: { creatorClass: DocumentCreatorMTO_FSOL_08_1_3_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            224: { creatorClass: DocumentCreatorMTO_FSOL_08_1_4_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            225: { creatorClass: DocumentCreatorMTO_FSOL_08_1_5_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            222: { creatorClass: DocumentCreatorMTO_FSOL_08_2_1_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            223: { creatorClass: DocumentCreatorMTO_FSOL_08_2_3_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            275: { creatorClass: DocumentCreatorMTO_FSOL_08_2_4_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            218: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            276: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v2, requirement_1: true, requirement_2: this.signatureUrl },
                            214: { creatorClass: DocumentCreatorMTO_FSOL_09_v2, requirement_1: this.signatureUrl },
                            209: { creatorClass: DocumentCreatorMTO_FSOL_10_v2, requirement_1: this.signatureUrl },
                            210: { creatorClass: DocumentCreatorMTO_FSOL_11_v2, requirement_1: this.signatureUrl },
                            220: { creatorClass: DocumentCreatorMTO_FSOL_12_v2, requirement_1: this.signatureUrl },
                            221: { creatorClass: DocumentCreatorMTO_FSOL_13_v2, requirement_1: this.signatureUrl },
                            211: { creatorClass: DocumentCreatorMTO_FSOL_14_v2, requirement_1: this.signatureUrl },
                            278: { creatorClass: DocumentCreatorMTO_FSOL_21_v2, requirement_1: this.signatureUrl },
                            217: { creatorClass: DocumentCreatorMTO_FSOL_24_v2, requirement_1: this.signatureUrl },
                            // Version 3
                            300: { creatorClass: DocumentCreatorMTO_FSOL_01_v3, requirement_1: this.signatureUrl },
                            301: { creatorClass: DocumentCreatorMTO_FSOL_02_1_v3, requirement_1: this.signatureUrl },
                            379: { creatorClass: DocumentCreatorMTO_FSOL_02_2_v3, requirement_1: this.signatureUrl },
                            394: { creatorClass: DocumentCreatorMTO_FSOL_02_3_v3, requirement_1: this.signatureUrl },
                            302: { creatorClass: DocumentCreatorMTO_FSOL_04_1_v3, requirement_1: this.signatureUrl },
                            370: { creatorClass: DocumentCreatorMTO_FSOL_04_2_v3, requirement_1: this.signatureUrl },
                            316: { creatorClass: DocumentCreatorMTO_FSOL_04_3_v3, requirement_1: this.signatureUrl },
                            395: { creatorClass: DocumentCreatorMTO_FSOL_04_4_v3, requirement_1: this.signatureUrl },
                            303: { creatorClass: DocumentCreatorMTO_FSOL_05_1_v3, requirement_1: this.signatureUrl },
                            371: { creatorClass: DocumentCreatorMTO_FSOL_05_3_v3, requirement_1: this.signatureUrl },
                            304: { creatorClass: DocumentCreatorMTO_FSOL_06_v3, requirement_1: this.signatureUrl },
                            312: { creatorClass: DocumentCreatorMTO_FSOL_08_1_1_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            372: { creatorClass: DocumentCreatorMTO_FSOL_08_1_2_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            373: { creatorClass: DocumentCreatorMTO_FSOL_08_1_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            324: { creatorClass: DocumentCreatorMTO_FSOL_08_1_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            325: { creatorClass: DocumentCreatorMTO_FSOL_08_1_5_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            322: { creatorClass: DocumentCreatorMTO_FSOL_08_2_1_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            323: { creatorClass: DocumentCreatorMTO_FSOL_08_2_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            375: { creatorClass: DocumentCreatorMTO_FSOL_08_2_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            318: { creatorClass: DocumentCreatorMTO_FSOL_08_3_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            376: { creatorClass: DocumentCreatorMTO_FSOL_08_4_v3, requirement_1: true, requirement_2: this.signatureUrl },
                            314: { creatorClass: DocumentCreatorMTO_FSOL_09_v3, requirement_1: this.signatureUrl },
                            309: { creatorClass: DocumentCreatorMTO_FSOL_10_v3, requirement_1: this.signatureUrl },
                            310: { creatorClass: DocumentCreatorMTO_FSOL_11_v3, requirement_1: this.signatureUrl },
                            320: { creatorClass: DocumentCreatorMTO_FSOL_12_v3, requirement_1: this.signatureUrl },
                            321: { creatorClass: DocumentCreatorMTO_FSOL_13_v3, requirement_1: this.signatureUrl },
                            311: { creatorClass: DocumentCreatorMTO_FSOL_14_v3, requirement_1: this.signatureUrl },
                            327: { creatorClass: DocumentCreatorMTO_FSOL_14_UE_v3, requirement_1: this.signatureUrl },
                            378: { creatorClass: DocumentCreatorMTO_FSOL_21_v3, requirement_1: this.signatureUrl },
                            317: { creatorClass: DocumentCreatorMTO_FSOL_24_v3, requirement_1: this.signatureUrl },
                        }

                        const methodMappings = {
                            // Version 1
                            0: 'create_MTO_FSOL_01',
                            1: 'create_MTO_FSOL_02',
                            2: 'create_MTO_FSOL_04_1',
                            16: 'create_MTO_FSOL_04_2',
                            3: 'create_MTO_FSOL_05',
                            4: 'create_MTO_FSOL_06',
                            12: 'create_MTO_FSOL_08_1',
                            24: 'create_MTO_FSOL_08_4',
                            25: 'create_MTO_FSOL_08_5',
                            22: 'create_MTO_FSOL_08_2',
                            23: 'create_MTO_FSOL_08_7',
                            18: 'create_MTO_FSOL_08_3',
                            14: 'create_MTO_FSOL_09',
                            9: 'create_MTO_FSOL_10',
                            10: 'create_MTO_FSOL_11',
                            11: 'create_MTO_FSOL_14',
                            17: 'create_MTO_FSOL_24',
                            // Version 2
                            200: 'create_MTO_FSOL_01',
                            201: 'create_MTO_FSOL_02_1',
                            279: 'create_MTO_FSOL_02_2',
                            202: 'create_MTO_FSOL_04_1',
                            270: 'create_MTO_FSOL_04_2',
                            216: 'create_MTO_FSOL_04_3',
                            203: 'create_MTO_FSOL_05_1',
                            271: 'create_MTO_FSOL_05_3',
                            204: 'create_MTO_FSOL_06',
                            212: 'create_MTO_FSOL_08_1_1',
                            272: 'create_MTO_FSOL_08_1_2',
                            273: 'create_MTO_FSOL_08_1_3',
                            224: 'create_MTO_FSOL_08_1_4',
                            225: 'create_MTO_FSOL_08_1_5',
                            222: 'create_MTO_FSOL_08_2_1',
                            223: 'create_MTO_FSOL_08_2_3',
                            275: 'create_MTO_FSOL_08_2_4',
                            218: 'create_MTO_FSOL_08_3',
                            276: 'create_MTO_FSOL_08_4',
                            214: 'create_MTO_FSOL_09',
                            209: 'create_MTO_FSOL_10',
                            210: 'create_MTO_FSOL_11',
                            220: 'create_MTO_FSOL_12',
                            221: 'create_MTO_FSOL_13',
                            211: 'create_MTO_FSOL_14',
                            278: 'create_MTO_FSOL_21',
                            217: 'create_MTO_FSOL_24',
                            // Version 3
                            300: 'create_MTO_FSOL_01',
                            301: 'create_MTO_FSOL_02_1',
                            379: 'create_MTO_FSOL_02_2',
                            394: 'create_MTO_FSOL_02_3',
                            302: 'create_MTO_FSOL_04_1',
                            370: 'create_MTO_FSOL_04_2',
                            316: 'create_MTO_FSOL_04_3',
                            395: 'create_MTO_FSOL_04_4',
                            303: 'create_MTO_FSOL_05_1',
                            371: 'create_MTO_FSOL_05_3',
                            304: 'create_MTO_FSOL_06',
                            312: 'create_MTO_FSOL_08_1_1',
                            372: 'create_MTO_FSOL_08_1_2',
                            373: 'create_MTO_FSOL_08_1_3',
                            324: 'create_MTO_FSOL_08_1_4',
                            325: 'create_MTO_FSOL_08_1_5',
                            322: 'create_MTO_FSOL_08_2_1',
                            323: 'create_MTO_FSOL_08_2_3',
                            375: 'create_MTO_FSOL_08_2_4',
                            318: 'create_MTO_FSOL_08_3',
                            376: 'create_MTO_FSOL_08_4',
                            314: 'create_MTO_FSOL_09',
                            309: 'create_MTO_FSOL_10',
                            310: 'create_MTO_FSOL_11',
                            320: 'create_MTO_FSOL_12',
                            321: 'create_MTO_FSOL_13',
                            311: 'create_MTO_FSOL_14',
                            327: 'create_MTO_FSOL_14',
                            378: 'create_MTO_FSOL_21',
                            317: 'create_MTO_FSOL_24',
                        }

                        if(this.formId in formMappings) {
                            const showForm = [
                                // MTO-FSOL-08's V1
                                12, 24, 25, 22, 23, 18,
                                // MTO-FSOL-08's V2
                                212, 272, 273, 224, 225, 222, 274, 223, 275, 218, 276,
                                // MTO-FSOL-08's V3
                                312, 372, 373, 324, 325, 322, 374, 323, 375, 318, 376
                            ].includes(this.formId)

                            const document = await this.generateDocument(true, true, showForm, formMappings, methodMappings);
                            this.buildDocument(document, '')
                        } else {
                            this.showDoc = false
                            this.showForm = true
                            this.docloading = false

                            if (this.submission !== null) {
                                let lastForm = {}
                                for (var i in this.submission) {
                                    const obj = this.submission[i]
                                    lastForm[obj.name] = obj.value
                                }
                                setTimeout(() => {
                                    this.communication.emitEvent({
                                        identifier: 'set-values',
                                        form: lastForm
                                    })
                                }, 100)
                            }

                            setTimeout(() => {
                                this.communication.emitEvent({
                                    identifier: 'submit'
                                })
                            }, 100)
                        }
                    },
                    error => {
                        this.alertService.error(error);
                    }
                )
        }

        this.form = this.formBuilder.group({
            password: ['', Validators.required],
            comments: ['']
        });

        this.formOrder = this.formBuilder.group({
            password: ['', Validators.required],
            comments: ['', Validators.required]
        });
    }

    // Convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    get fOrder() { return this.formOrder.controls; }

    onSubmit() {
        this.submitted = true;
        this.saving = true
        // Reset alerts on submit
        this.alertService.clear();

        // Stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        const formsFSOL08s = [
            12, 24, 25, 22, 23, 18,
            212, 272, 273, 224, 225, 222, 274, 223, 275, 218, 276,
            312, 372, 373, 324, 325, 322, 374, 323, 375, 318, 376
        ]
        const formsFSOL07s = [30, 230, 330]

        if (this.form.value.password && !this.toSave) {
            this.loading = true;
            this.forbiddenId = false
            this.authenticationService.isPasswordValidated(this.form.value.password)
                .pipe(first())
                .subscribe(
                    async data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })

                            if (this.isEvaluation) {
                                //console.log("esta entrando aqui")
                                // Save form
                                await this.communication.emitEvent({
                                    identifier: 'submit'
                                })

                                this.evaluationService.signEvaluation(this.reviewId, this.formId)
                                    .pipe(first())
                                    .subscribe(
                                        response => {
                                            this.saving = false
                                            this.modal.close(true)
                                            this.modal.dismiss()
                                        },
                                        error => {
                                            this.alertService.error(error);
                                        })
                            } else if (this.isApplication) {
                                this.requestsService.signDocument(this.reviewId, this.formId)
                                    .pipe(first())
                                    .subscribe(
                                        response => {
                                            this.saving = false
                                            this.modal.close(true)
                                            this.modal.dismiss()
                                        },
                                        error => {
                                            this.alertService.error(error);
                                        })
                            } else {
                                if (formsFSOL08s.includes(this.formId)) {
                                    //console.log("es 08")
                                    // Save form
                                    await this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                } else if(formsFSOL07s.includes(this.formId)) {
                                    //console.log("es 07")
                                    // Save form
                                    await this.communication.emitEvent({
                                        identifier: 'submit'
                                    })
                                } else {
                                    //console.log("no es 08 ni 07")
                                    // Save form
                                    if (this.showForm) {
                                        await this.communication.emitEvent({
                                            identifier: 'submit'
                                        })
                                    } else if (this.showDoc) {
                                        this.reviewsService.signReview(this.reviewId, this.formId)
                                            .pipe(first())
                                            .subscribe(
                                                response => {
                                                    this.saving = false
                                                    this.modal.close(true)
                                                    this.modal.dismiss()
                                                },
                                                error => {
                                                    this.alertService.error(error);
                                                })
                                    }
                                }
                            }
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }

    }

    deny() {
        //console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        this.formOrder.controls["comments"].setValidators([Validators.required])
        this.formOrder.controls["comments"].updateValueAndValidity()

        // Stop here if form is invalid
        if (this.formOrder.invalid) {
            return;
        }
        if (this.formOrder.value.password) {
            this.forbiddenId = false
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.formOrder.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })
                            this.inspectionService.denyInspection(this.inspectionId, this.formOrder.value.comments)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        this.forbiddenId = false
                                        this.alertService.success('Inspección denegada', { autoClose: true })
                                        this.modal.close(true)
                                        this.modal.dismiss()
                                    },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    }
                                )
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }

    approve() {
        //console.log("SUBMITT")
        this.submitted = true;

        // Reset alerts on submit
        this.alertService.clear();

        this.formOrder.controls["comments"].clearValidators()
        this.formOrder.controls["comments"].updateValueAndValidity()

        // Stop here if form is invalid
        if (this.formOrder.invalid) {
            return;
        }
        if (this.formOrder.value.password) {
            this.forbiddenId = false
            this.loading = true;
            this.authenticationService.isPasswordValidated(this.formOrder.value.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.alertService.clear()
                        if (data.validated) {
                            this.alertService.success('Contraseña valida', { autoClose: true })
                            this.inspectionService.acceptInspection(this.inspectionId)
                                .pipe(first())
                                .subscribe(response => {
                                    this.forbiddenId = false
                                    this.alertService.success('Inspección aprobada', { autoClose: true })
                                    this.modal.close(true)
                                    this.modal.dismiss()
                                },
                                    error => {
                                        this.alertService.error(error);
                                        this.loading = false;
                                    })
                        } else {
                            this.forbiddenId = true
                        }
                        this.loading = false;
                    },
                    error => {
                        this.alertService.error(error);
                        this.loading = false;
                    });
        }
    }

    // Get object data from requirements
    getValueListRequirements(requirements, name) {
        var value = null
        for (const i in requirements) {
            const element = requirements[i]
            if (element.requiredInto === name) {
                value = element.data
            }
        }
        if (value) return value
        else return []
    }

    // Get value from submission
    getValueSubmission(submission, name) {
        var value = null
        for (const i in submission) {
            const element = submission[i]
            if (element.name === name) {
                value = element.value
            }
        }
        if (value) return value
        else return ''
    }

    // Get document by URL
    async getDocumentByURL(tmpURL, document, itemName) {
        await this.inspectionService.getUrlStatus(tmpURL)
            .pipe(first())
            .subscribe(
                data => {
                    //console.log(data)
                },
                error => {
                    //console.log(error)
                    if (error && error === 'OK') {
                        this.url = tmpURL
                        this.docloading = false
                    } else {
                        this.buildDocument(document, itemName)
                    }
                }
            )
    }

    // Build document
    buildDocument(document, itemName) {
        /* const blob2 = await Packer.toBlob(document)
        saveAs(blob2, "document") */

        Packer.toBlob(document).then(blob => {
            this.bdoc = blob
            this.reviewsService.uploadPreview(this.bdoc, 'docx').subscribe(data => {
                if (data.success) {
                    const u = data.url

                    //console.log(u, "evaluation")
                    //localStorage.setItem(itemName, u)

                    setTimeout(() => {
                        this.url = u
                        this.docloading = false
                    }, 3000);
                }
            }, error => {
                console.log(error)
            })
        });
    }
}